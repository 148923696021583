// @flow strict-local
import type {
  AcceptanceRate,
  VideoSettings,
  Year,
} from './GenericTypes';
import {TBD, WORKSHOP, JOURNAL, CHAPTER, ARXIV, DISSERTATION} from './GenericTypes';
//import ec from './ec.mp4';

export type PublicationMedia = {|
  publisher: string,
  title: string, // Text that will be displayed to the user
  uri: string,
|}

export type PublicationData = {
  acceptanceRate: AcceptanceRate,
  conference: string,
  year: Year,
  authors: string,
  title: string,
  uri?: string,
  authorsNote?: string, // **NOT Markdown**.  Displayed in Italics as a substring.
  bestPaper?: boolean,
  bestPaperRunnerUp?: boolean,
  bestPaperHonorableMention?: boolean,
  bestPresentation?: boolean,
  newHorizon?: boolean,
  diversity?: boolean,
  presentation?: VideoSettings, // Defined in "Video.react"
  articles?: Array<PublicationMedia>,
}

const PUBLICATIONS: Array<PublicationData> = [
   {
    acceptanceRate:TBD,
    conference: 'USENIX Security',
    year: 2025,
    authors: 'Gibson, C., Olszewski, D., Brigham, N.G., Crowder, A., Butler, K.R.B., Traynor, P., **Redmiles, E.M.**, Kohno, T.',
    title: 'Analyzing the AI Nudification Application Ecosystem',
    uri: 'https://arxiv.org/abs/2407.10546',
  },
   {
    acceptanceRate:TBD,
    conference: 'ACM CHI',
    year: 2025,
    authors: 'Lima, G., Grgić-Hlača, N., **Redmiles, E.M.**',
    title: 'Public Opinions About Copyright for AI-Generated Art: The Role of Egocentricity, Competition, and Experience',
    uri: 'https://arxiv.org/abs/2407.10546',
  },
  {
    acceptanceRate:TBD,
    conference: 'ACM CSCW',
    year: 2024,
    authors: 'Rivera, V., Wilkinson, D., Augusta, A., Li, S., **Redmiles, E.M.**, Strohmayer, A.',
    title: 'Safer Algorithmically-Mediated Offline Introductions: Harms and Protective Behaviors',
    uri: 'https://www.nist.gov/system/files/documents/2024/02/15/ID017%20-%202024-02-02%20Massive%20Data%20Institute%20%28Georgetown%29%2C%20Attachment%201%2C%20Reference%20Paper.pdf',
  },
  {
    acceptanceRate:TBD,
    conference: 'ACM CCS',
    year: 2024,
    authors: 'Childs, K., Gibson, C., Crowder, A., Warren, K., Stillman, C., **Redmiles, E.M.**, Jain, E., Traynor, P., Butler, K.', 
    title: '"I Had Sort of a Sense that I Was Always Being Watched...Since I Was": Examining Interpersonal Discomfort From Continuous Location-Sharing Applications',
    uri: 'https://github.com/FICS/CLS-Application-Discomfort/blob/main/Examining%20Interpersonal%20Discomfort%20From%20Continuous%20Location-Sharing%20Applications%20Extended.pdf',
  },
   {
    acceptanceRate:18,
    conference: 'USENIX Security',
    year: 2024,
    authors: 'Qin, L., Hamilton, V., Wang, S., Aydinalp, Y., Scarlett, M., **Redmiles, E.M.**',
    title: '"Did They Consent to That?": Safer Digital Intimacy via Proactive Protection Against Image-Based Sexual Abuse',
    uri: 'https://arxiv.org/abs/2403.04659',
  },
  {
    acceptanceRate:18,
    conference: 'USENIX Security',
    year: 2024,
    authors: 'Soneji, A., Hamilton, V., Doupe, A., McDonald, A., **Redmiles, E.M.**',
    title: '"I feel physically safe but not politically safe": Understanding the Digital Threats and Safety Practices of OnlyFans Creators',
    uri: 'https://www.usenix.org/conference/usenixsecurity24/presentation/soneji',
  },
  {
    acceptanceRate:18,
    conference: 'USENIX Security',
    year: 2024,
    authors: 'Wei, M., Mink, J., Eiger, Y., Kohno, T., **Redmiles, E.M.**, Roesner, F.',
    title: 'SoK or So(L)K? On the Quantitative Study of Sociodemographic Factors and Computer Security Behaviors',
    uri: 'https://www.usenix.org/conference/usenixsecurity24/presentation/wei-miranda',
  },
    {
    acceptanceRate:18,
    conference: 'USENIX Security',
    year: 2024,
    authors: 'Grober, L., Arshad, W., Shanza, Goetzen, A., **Redmiles, E.M.**, Mustafa, M., Kromholz, K.',
    title: '"I chose to fight, be brave, and to deal with it": Threat Experiences and Security Practices of Pakistani Content Creators',
    uri: 'https://www.usenix.org/conference/usenixsecurity24/presentation/gr%C3%B6ber',
  },
  {
    acceptanceRate:TBD,
    conference: 'USENIX SOUPS',
    year: 2024,
    authors: 'Brigham, N.G., Wei, M., Kohno, T., **Redmiles, E.M.**',
    title: '"Violation of my body:" Perceptions of AI-generated non-consensual (intimate) imagery',
    uri: 'https://www.usenix.org/system/files/soups2024-brigham.pdf',
  },
  {
    acceptanceRate:26.4,
    conference: 'ACM CHI',
    year: 2024,
    authors: 'Mink, J., Wei, M., Munyendo, C.W., Hugenberg, K., Kohno, T., **Redmiles, E.M.**, Wang, G.',
    title: 'It’s Trying Too Hard To Look Real: Deepfakes Moderation Mistakes and Identity-Based Bias',
    uri: `https://dl.acm.org/doi/10.1145/3613904.3641999`,
  },
  {
    acceptanceRate:26.4,
    conference: 'ACM CHI',
    year: 2024,
    authors: 'Zannettou, S., Nemes-Nemeth, O., Ayalon, O., Goetzen, A., Gummadi, K.P., **Redmiles, E.M.**, Roesner, F.',
    title: 'Analyzing User Engagement with TikTok’s Short Format Video Recommendations using Data Donations',
      uri: 'https://dl.acm.org/doi/10.1145/3613904.3642433',
  },

  {
    acceptanceRate:JOURNAL,
    conference: 'IEEE S&P Magazine',
    year: 2024,
    authors: '**Redmiles, E.M.**',
    title: 'Friction Matters: Balancing the Pursuit of Perfect Protection With Target Hardening',
    uri: `${process.env.PUBLIC_URL}/research/sexworkSPMag2024.pdf`,
  },
     {
    acceptanceRate:JOURNAL,
    conference: 'IEEE S&P Magazine',
    year: 2024,
    authors: 'Hamilton,V., Kaptchuk,G., McDonald,A., and **Redmiles, E.M.**',
    title: 'Safer Digital Intimacy for Sex Workers and Beyond: A Technical Research Agenda',
    uri: `${process.env.PUBLIC_URL}/research/sexworkSPMag2024.pdf`,
  },
    {
    acceptanceRate:TBD,
    conference: 'USENIX Security',
    year: 2023,
    authors: 'Ayalon, O., Turjeman, D., and **Redmiles, E.M.**',
    title: 'Exploring Privacy and Incentives Considerations in mHealth Technology Adoption: A Case Study of COVID-19 Contact Tracing Apps',
    uri: 'https://www.usenix.org/conference/usenixsecurity23/presentation/ayalon',
  },
    {
    acceptanceRate:TBD,
    conference: 'USENIX Security',
    year: 2023,
    authors: 'Nanayakkara, P., Smart, M.A., Cummings, R., Kaptchuk, G., and **Redmiles, E.M.**',
    title: 'What Are the Chances? Explaining the Epsilon Parameter in Differential Privacy',
    uri: 'https://www.usenix.org/conference/usenixsecurity23/presentation/nanayakkara',
  },
    {
    acceptanceRate:TBD,
    conference: 'USENIX Security',
    year: 2023,
    authors: 'Ali, M.*, Goetzen, A.*, Mislove, A., **Redmiles, E.M.**, and Sapiezynski, P.',
    title: 'Problematic Advertising and its Disparate Exposure on Facebook',
    uri: 'https://www.usenix.org/conference/usenixsecurity23/presentation/ali',
  },
    {
    acceptanceRate:28,
    conference: 'CHI',
    year: 2023,
    authors: 'Hamilton, V., Soneji, A., McDonald, A., and **Redmiles, E.M.**',
    title: '"Nudes? Shouldn\'t I charge for these?": Motivations of New Sexual Content Creators on OnlyFans',
    uri: 'https://dl.acm.org/doi/abs/10.1145/3544548.3580730',
    bestPaperHonorableMention: true,
  },
     {acceptanceRate:JOURNAL,
    conference: 'IEEE S&P Magazine',
    year: 2023,
    authors: '**Redmiles, E.M.**, Bennett, M., Kohno, T.',
    title: 'Power in Computer Security and Privacy: A Critical Lens',
    uri: `${process.env.PUBLIC_URL}/research/powerInComputerSecurity2023.pdf`,
     },
     {acceptanceRate:JOURNAL,
    conference: 'IMWUT/Ubicomp',
    year: 2023,
    authors: 'Oshrat, A., Li, S., Preneel, B., and **Redmiles, E.M.**',
    title: 'Not only for contact tracing: Use of Belgium\'s contact tracing app among young adults',
    uri: 'https://dl.acm.org/doi/abs/10.1145/3570348',
  },
  {
    acceptanceRate:24,
    conference: 'CHI',
    year: 2022,
    authors: 'Dooley, S., Turjeman, D., Dickerson, J.P. and **Redmiles, E.M.**',
    title: 'Field Evidence of the Effects of Pro-sociality and Transparency on COVID-19 App Attractiveness',
    uri: 'https://dl.acm.org/doi/abs/10.1145/3491102.3501869',
    bestPaperHonorableMention: true,
  },
    {
    acceptanceRate:24,
    conference: 'CHI',
    year: 2022,
    authors: 'Breen, C. F., Herley, C. and **Redmiles, E.M.**',
    title: 'A Large-Scale Measurement of Cybercrime Against Individuals',
    uri: `https://dl.acm.org/doi/abs/10.1145/3491102.3517613`,
  },
   {acceptanceRate:JOURNAL,
    conference: 'Journal of Online Trust and Safety',
    year: 2022,
    authors: 'Munyaka, I. N. S., Hargittai, E., and **Redmiles, E.M.**',
    title: 'The Misinformation Paradox: Older Adults are Cynical about News Media, but Engage with it Anyway',
    uri: 'https://doi.org/10.54501/jots.v1i4.62',
  },
     {acceptanceRate:TBD,
       conference: 'EAAMO',
    year: 2022,
    authors: 'Grgić-Hlača, N., Lima, G., Weller, A., and **Redmiles, E.M.**',
    title: 'Dimensions of Diversity in Human Perceptions of Algorithmic Fairness',
    uri: 'https://eaamo.org/papers/grgic-hlaca-22.pdf',
    newHorizon: true,
    
  },
      {
    acceptanceRate:JOURNAL,
    conference: 'CSCW',
    year: 2022,
    authors: 'Bhalerao, R., McDonald, N., Barakat, H. L., Hamilton, V., McCoy, D., and **Redmiles, E.M.**',
    title: 'Ethics and Efficacy of Unsolicited Anti-Trafficking SMS Outreach',
    uri: 'https://dl.acm.org/doi/10.1145/3555083',
    diversity: true,
  },
 {acceptanceRate:JOURNAL,
    conference: 'CSCW',
    year: 2022,
    authors: 'Hamilton, V., Barakat, H. L., and **Redmiles, E.M.**',
    title: 'Risk, Resilience and Reward: Impacts of Shifting to Digital Sex Work',
    uri: 'https://dl.acm.org/doi/10.1145/3555650',
  },
 {
    acceptanceRate:24,
    conference: 'PoPETS',
    year: 2022,
    authors: 'Goetzen, A., Dooley, S., and **Redmiles, E.M**',
    title: 'Ctrl-Shift: How Privacy Sentiment Changed from 2019 to 2021',
    uri: 'https://arxiv.org/abs/2110.09437',
      articles: [
      {
        publisher: 'Scientific American',
        title: 'Political Affiliation Influences Our Fear of Data Collection',
        uri: 'https://www.scientificamerican.com/article/political-affiliation-influences-our-fear-of-data-collection/'
      }]
  //  bestPaperHonorableMention: true,
  },
   {
    acceptanceRate:8,
    conference: 'ICWSM',
    year: 2022,
    authors: 'Barakat, H.L. and **Redmiles, E.M.**',
    title: 'Community Under Surveillance: Impacts of Marginalization on an Online Labor Forum',
    uri: 'https://osf.io/preprints/socarxiv/74zw2/',
  },
     {
    acceptanceRate:16,
    conference: 'USENIX Security',
    year: 2022,
    authors: 'Geeng, C., Harris, M., **Redmiles, E. M.**, Roesner, F.',
    title: '"Like Lesbians Walking the Perimeter": Experiences of U.S. LGBTQ+ Folks With Online Security, Safety, and Privacy Advice',
    uri: 'https://www.usenix.org/conference/usenixsecurity22/presentation/geeng',
  },
  {
    acceptanceRate:37,
    conference: 'CHIIR',
    year: 2022,
    authors: 'Huang, Y., Obada-Obieh, B., **Redmiles, E. M.**, Lokam, S. and Beznosov, K.',
    title: 'COVID-19 Information Tracking Solutions: A Qualitative Investigation of the Factors Influencing People\'s Adoption',
    uri: 'https://orange.hosting.lsoft.com/trk/clickp?ref=znwrbbrs9_6-2d8c7x32fbcax01942&doi=3498366.3505756',
  },
         {
    acceptanceRate:WORKSHOP,
    conference: 'EthiCS',
    year: 2022,
    authors: 'Bhalerao, R., Hamilton, V., McDonald, A., **Redmiles, E.M.** and Strohmayer, A.',
    title: 'Ethical Practices for Security Research with At-Risk Populations',
    uri: 'https://ieeexplore.ieee.org/document/9799425',
   bestPaper: true,
  },
            {
    acceptanceRate:WORKSHOP,
    conference: 'ConPro',
    year: 2022,
    authors: 'Ali, M., Goetzen, A., Misolve, A., **Redmiles, E.M.** and Sapiezynski, P.',
    title: 'All Things Unequal: Measuring Disparity of Potentially Harmful Ads on Facebook',
    uri: 'https://www.ieee-security.org/TC/SPW2022/ConPro/papers/ali-conpro22.pdf',
  //  bestPaperHonorableMention: true,
  },
  {
    acceptanceRate:19,
    conference: 'USENIX Security',
    year: 2021,
    authors: 'McDonald, A., Barwulor, C., Schaub, F., Mazurek, M.L., and **Redmiles, E.M.**',
    title: '"It’s stressful having all these phones": Investigating Sex Workers’ Safety Goals, Risks, and Practices Online',
    uri: 'https://www.usenix.org/conference/usenixsecurity21/presentation/mcdonald',
    bestPaper: true,
  },
    {
    acceptanceRate:19,
    conference: 'USENIX Security',
    year: 2021,
    authors: 'Golla, M., Ho, G., Lohmus, M., Pulluri, M., and **Redmiles, E.M.**',
    title: 'Driving 2FA Adoption at Scale: Optimizing Two-Factor Authentication Notification Design Patterns',
    uri: `https://www.usenix.org/conference/usenixsecurity21/presentation/golla`,
  },
  {
    acceptanceRate:22,
    conference: 'ACM CCS',
    year: 2021,
    authors: 'Cummings, R., Kaptchuk, G., and **Redmiles, E.M.**',
    title: '"I need a better description": An Investigation Into User Expectations For Differential Privacy',
    uri:'https://arxiv.org/abs/2110.06452',
    bestPaperRunnerUp: true,
  },
    {
    acceptanceRate:22,
    conference: 'ACM CCS',
    year: 2021,
    authors: 'Wong, M.Y., Landen, M., Antonakakis, M., Blough, D.M., **Redmiles, E.M.** and Ahamad, M.',
    title: 'An Inside Look into the Practice of Malware Analysis',
    uri:`${process.env.PUBLIC_URL}/research/malwareanalysisCCS2021.pdf`,
  },
  {
    acceptanceRate:JOURNAL,
    conference: 'ACM CSCW',
    year: 2021,
    authors: 'Kross, S., Hargittai, E., and **Redmiles, E.M.**',
    title: 'Characterizing the Online Learning Landscape: What and How People Learn Online',
    uri: 'https://arxiv.org/abs/2102.05268',
  },
  {
    acceptanceRate:26,
    conference: 'ACM CHI',
    year: 2021,
    authors: 'Barwulor, C., McDonald, A., Hargittai, E., and **Redmiles, E.M.**',
    title: '"Disadvantaged in the American-dominated internet": Sex, Work, and Technology',
    uri: 'https://dl.acm.org/doi/10.1145/3411764.3445378',
  },
  
   {
    acceptanceRate:24,
    conference: 'RAID',
    year: 2021,
    authors: 'Sherman, I., Stokes, J.W., **Redmiles, E.M.**',
    title: 'Designing Media Provenance Indicators to Combat Fake Media',
    uri: 'http://arxiv.org/abs/2010.00544',
  },
    {
    acceptanceRate:JOURNAL,
    conference: 'ACM DTRAP',
    year: 2021,
    authors: 'Kaptchuk, G., Goldstein, D.G., Hargittai, E., Hofman, J., **Redmiles, E.M.**',
    title: 'How good is good enough for COVID19 apps? The influence of benefits, accuracy, and privacy on willingness to adopt',
    uri: 'https://dl.acm.org/doi/10.1145/3488307',
  },
  {
    acceptanceRate:CHAPTER,
    conference: 'Perspectives on Digital Humanism',
    year: 2021,
    authors: '**Redmiles, E.M.**',
    title: 'The Need for Respectful Technologies: Going Beyond Privacy ',
    uri: 'https://dighum.ec.tuwien.ac.at/perspectives-on-digital-humanism/the-need-for-respectful-technologies-going-beyond-privacy/',
  },
  {
    acceptanceRate:CHAPTER,
    conference: 'Handbook on Digital Inequality',
    year: 2021,
    authors: '**Redmiles, E.M.** and Buntain, C.J.',
    title: 'How Feelings of Trust, Concern, and Control of Personal Online Data Influence Web Use',
    uri: `${process.env.PUBLIC_URL}/research/BuntainRedmiles_2022.pdf`,
  },
  {
    acceptanceRate:22,
    conference: 'ACM MMSys',
    year: 2021,
    authors: 'England, P., Malvar, H.S., Horvitz, E., Stokes, J.W., Fournet, C., Aguero, R.B., Chamayou, A., Clebsch, S., Costa, M., Deutscher, J., Erfani, S., Gaylor, M., Jenks, A., Kane, K., **Redmiles, E.M.**, Shamis, A., Sharma, I., Wenker, S., Zaman, A.',
    title: 'AMP: Authentication of Media via Provenance',
    uri: 'https://dl.acm.org/doi/10.1145/3458305.3459599',
  },
  {
  conference: 'WPES',
  acceptanceRate:WORKSHOP,
  year: 2021,
  authors: 'Akgul, O. Abu-Salma, R., Bai, W., **Redmiles, E.M.**, Mazurek, M.L., Ur, B.',
  title: 'From Secure to Military-Grade: Exploring the Effect of App Descriptions on User Perceptions of Secure Messaging'
},
      {
    acceptanceRate:ARXIV,
    conference: 'ARXIV',
    year: 2021,
    authors: 'Lut, Y., Wang, M., **Redmiles, E.M.** and Cummings, R.',
    title: 'How we browse: Measurement and analysis of digital behavior',
    uri: 'https://arxiv.org/abs/2108.06745',
  },
  {acceptanceRate: JOURNAL,
   conference: 'First Monday',
   year: 2020,
   authors: 'Hargittai, E., **Redmiles, E.M.**, Vitak, J., and Zimmer, M.',
   title: "Americans’ Willingness to Adopt a COVID-19 Tracking App: The Role of App Distributor",
   uri: 'https://firstmonday.org/ojs/index.php/fm/article/view/11095',
  },
  {
    acceptanceRate:16,
    conference: 'USENIX Security',
    year: 2020,
    authors: '**Redmiles, E.M.**, Warford, N., Jayanti, A., Koneru, A., Kross, S., Morales, M., Stevens, R., Mazurek, M.L.',
    title: 'A Comprehensive Quality Evaluation of Security and Privacy Advice on the Web',
    uri: 'https://www.usenix.org/conference/usenixsecurity20/presentation/redmiles',
    bestPaper: true,
  },
{
    acceptanceRate: JOURNAL,
    conference: 'ACM DGOV',
    year: 2020,
    authors: '**Redmiles, E.M.**',
    title: 'User Concerns & Tradeoffs in Technology-Facilitated Contact Tracing',
    uri: 'https://dl.acm.org/doi/10.1145/3428093',
  },
 {
    acceptanceRate: 28,
    conference: 'ITiCSE',
    year: 2020,
    authors: 'McFarlane, D. and **Redmiles, E.M.**',
    title: 'Get Paid to Program: Evaluating an Employment-Aware After-School Program for High School Women of Color',
    uri: `${process.env.PUBLIC_URL}/research/iticse2020.pdf`,
  },
  {
    acceptanceRate:TBD,
    conference: 'IBC',
    year: 2020,
    authors: 'Aythora, J. , Burke-Aguero, R., Chamayou, A., Clebsch, S., Costa,M., Deutscher,  J., Earnshaw, N., Ellis,  L., England, P., Fournet, C., Gaylor, M., Halford, C., Horvitz, E., Jenks, A., Kane, K., Lavallee, M., Lowenstein, S., MacCormack, B., Malvar, H., O\'Brien, S., Parnall, J., **Redmiles, E.M.**, Shamis, A., Sharma, I., Stokes, J.W., Wenker, S., Zaman, A.',
    title: 'Multi-stakeholder Media Provenance Management to Counter Synthetic Media Risks in News Publishing',
    uri: 'https://www.ibc.org/download?ac=14528',
  },
  {
    acceptanceRate: CHAPTER,
    conference: 'Research No Longer Confidential',
    year: 2020,
    authors: '**Redmiles, E.M.**',
    title: 'Behind the Red Lights: Methods for Investigating the Digital Security & Privacy Experiences of Sex Workers',
    uri: `${process.env.PUBLIC_URL}/research/swmethods.pdf`
  },
 {
   acceptanceRate: DISSERTATION,
    conference: 'Ph.D. Dissertation',
    year: 2019,
    authors: '**Redmiles, E.M.**',
    title: 'Quality and Inequity in Digital Security Education',
    uri: 'https://drum.lib.umd.edu/handle/1903/25404',
  },
 {
    acceptanceRate: 23,
    conference: 'EMNLP',
    year: 2019,
    authors: '**Redmiles, E.M.**, Maszkiewicz, L., Hwang, E., Kuchhal, D., Liu, E., Morales, M., Peskov, D., Rao, S., Stevens, R., Gligoric, K., Kross, S., Mazurek, M.L. and Daume III, H.',
    title: 'Comparing and Developing Tools to Measure the Readability of Domain-Specific Texts',
    uri: `${process.env.PUBLIC_URL}/research/camera-ready-emnlp2019.pdf`,
  },
 {
    acceptanceRate: 12,
    conference: 'IEEE S&P',
    year: 2019,
    authors: '**Redmiles, E.M.**',
    title: '"Should I Worry?" A Cross-Cultural Examination of Account Security Incident Response',
    uri: 'https://arxiv.org/abs/1808.08177',
    presentation: {
      source: 'Youtube',
      uri: 'https://www.youtube.com/embed/UaK-c8TBYBI',
      config: {
        ratio: '16:9',
        title: '"Should I Worry?" A Cross Cultural Examination of Account Security Incident Response',
      },
    }
  },
  {
    acceptanceRate: 12,
    conference: 'IEEE S&P',
    year: 2019,
    authors: '**Redmiles, E.M.**, Kross, S., and Mazurek, M.L. ',
    title: 'How Well Do My Results Generalize? Comparing Security and '+
     'Privacy Survey Results from MTurk, Web, and Telephone Samples',
    uri: 'https://drum.lib.umd.edu/bitstream/handle/1903/19164/CS-TR-5054-rev1%20UPDATED%20VERSION.pdf?sequence=3&isAllowed=y',
     presentation: {
      source: 'Youtube',
      uri: 'https://www.youtube.com/embed/RlANM1ABcoc',
      config: {
        ratio: '16:9',
        title: '"How Well Do My Results Generalize? Comparing Security and Privacy Survey Results from MTurk, Web, and Telephone Samples',
      },
     }
  },
   {
    acceptanceRate: 17,
    conference: 'ICWSM',
    year: 2019,
    authors: '**Redmiles, E.M.**, Bodford, J., and Blackwell, L.',
    title: '"I just want to feel safe": A Diary Study of Safety Perceptions on Social Media',
    uri:'https://research.fb.com/wp-content/uploads/2019/06/icwsm_diary_fb.pdf?',
  },
  {acceptanceRate: JOURNAL,
   conference: 'Information, Communication & Society',
   year: 2019,
   authors: 'Micheli, M., **Redmiles, E.M.**, and Hargittai, E.',
   title: "Help wanted: young adults' sources of support for questions about digital media",
   uri: `${process.env.PUBLIC_URL}/research/ics-2019-preprint.pdf`,
  },
  {
    acceptanceRate: 18,
    conference: 'WWW',
    year: 2019,
    authors: 'Venkatadri, G., Sapiezynski, P., **Redmiles, E.M.**, Mislove, A., Goga, O., Mazurek, M.L., and Gummadi, K.P.',
    title: 'Auditing Offline Data Brokers via Facebook’s Advertising Platform',
     uri: `${process.env.PUBLIC_URL}/research/databrokers-measurement_finalCameraReady.pdf`,
  },
  {
    acceptanceRate: WORKSHOP,
    conference: 'USEC',
    year: 2019,
    authors: 'Parkin, S., **Redmiles, E.M.**, Coventry, L, Sasse, M.A.',
    title: 'Security When it is Welcome: Exploring Device Purchase as an Opportune Moment for Security Behavior Change',
     uri: `${process.env.PUBLIC_URL}/research/usec19-purchasing.pdf`,
  },
  {
    acceptanceRate: 24,
    conference: 'ACM FAT*',
    year: 2019,
    authors: 'Babaei, M., Kulshrestha, J., Chakraborty, A., ' +
      '**Redmiles, E.M.**, Cha, M., and Gummadi, K.P.',
    title: 'Analysing Biases in Perception of Truth in News Stories and' +
      ' their Implications for Fact Checking',
  },
  {
    acceptanceRate: 24,
    conference: 'ACM FAT*',
    year: 2019,
    authors: 'Ribeiro, F., Saha, K., Babaei, M., Henrique, L., Messias, J., Goga, O., Benevenuto, F., Gummadi, K.P., and **Redmiles, E.M.**',
    title: 'On Microtargeting Socially Divisive Ads: A Case Study of Russia-Linked Ad Campaigns on Facebook',
    uri :'https://arxiv.org/pdf/1808.09218.pdf',
  },
  {
    acceptanceRate: JOURNAL,
    conference: 'ACM TWEB',
    year: 2018,
    authors: '**Redmiles, E.M.** and Hargittai, E.',
    title: 'new phone, who dis? Modeling Millennials’ Backup Behavior',
     uri: `${process.env.PUBLIC_URL}/research/tweb-backup.pdf`,
  },
  {
    acceptanceRate: 17,
    conference: 'CCS',
    year: 2018,
    authors: '**Redmiles, E.M.**, Zhu, Z., Kross, S., Kuchhal, D., ' +
      'Dumitras, T., and Mazurek, M.L.',
    title: 'Asking for a Friend: Evaluating Response Biases in Security ' +
     'User Studies',
    uri: `${process.env.PUBLIC_URL}/research/updateselfreport.pdf`,
    presentation: {
      source: 'Youtube',
      uri: 'https://www.youtube.com/embed/6w4VEWCkLP0',
      config: {
        ratio: '16:9',
        title: 'Asking for a Friend: Evaluating Response Biases in Security User Studies',
      },
    },
  },
  {
    acceptanceRate: 17,
    conference: 'CCS',
    year: 2018,
    authors: 'Golla, M., Wei, M., Hainline, J., Filipe, L., Durmuth, ' +
      'M., **Redmiles, E.M.**, and Ur, B.',
    title: 'What was another site doing with my Facebook password in ' +
      'the first place? Designing Notifications About Password Reuse',
      uri: `${process.env.PUBLIC_URL}/research/pwdreuse.pdf`,
  },
  {
    acceptanceRate: WORKSHOP,
    conference: 'FOCI',
    year: 2018,
    authors: 'Abu-Salma, R., **Redmiles, E.M.**, Ur, B., and Wei, M.',
    title: 'Exploring User Mental Models of End-to-End Encrypted ' +
      'Communication Tools',
    uri: `${process.env.PUBLIC_URL}/research/foci18-final18.pdf`,
    articles: [
      {
        publisher: 'CNET',
        title: 'Why you need a better handle on the WhatsApp, Signal and Telegram apps',
        uri: 'https://www.cnet.com/news/you-might-not-really-understand-how-encrypted-messaging-apps-work/'
      },
      ],
  },
  {
    acceptanceRate: 19,
    conference: 'USENIX Security',
    year: 2018,
    authors: 'Stevens, R., Votipka, D., **Redmiles, E.M.**, Ahern, C., ' +
      'Sweeney, P., and Mazurek, M.L.',
    title: 'The Battle for New York: A Case Study of Applied Digital ' +
      'Threat Modeling at the Enterprise Level',
    uri: 'https://www.usenix.org/system/files/conference/usenixsecurity18/sec18-stevens.pdf',
    bestPaper: true,
  },
  {
    acceptanceRate: 23,
    conference: 'SOUPS',
    year: 2018,
    authors: 'Adams, D., Bah, A., Barwulor, C., Musabay, N., Pitkin, ' +
      'K., and ***Redmiles, E.M.**',
    title: 'Ethics Emerging: the Story of Privacy and Security Perceptions' +
    'in Virtual Reality',
    uri: 'https://drum.lib.umd.edu/handle/1903/20513',
    authorsNote: "*Sole advisor for five-person undergraduate student team.",
    articles: [
      {
        publisher: 'Salon',
        title: 'Think Facebook can manipulate you? Look out for virtual reality',
        uri: 'https://www.salon.com/2018/03/24/think-facebook-can-manipulate-you-look-out-for-virtual-reality_partner/'
      },
      {
        publisher: 'DailyBeast',
        title: 'Virtual Reality Is the Next Battleground for Your Data',
        uri: 'https://www.thedailybeast.com/virtual-reality-is-the-next-battleground-for-your-data',
      },
      {
        publisher: 'Business Insider',
        title: 'If you think Facebook can manipulate you, virtual reality\'s going to be a whole new ballgame',
        uri: 'https://www.businessinsider.com/if-you-think-facebook-is-manipulative-virtual-reality-could-be-worse-2018-3?r=UK&IR=T',
      },
      {
        publisher: 'CNBC',
        title: 'Virtual reality can potentially be more manipulative than Facebook',
        uri: 'https://www.cnbc.com/2018/03/22/virtual-reality-can-potentially-be-more-manipulative-than-facebook.html',
      },{
        publisher: 'Newsweek',
        title: 'Facebook\'s Psychological Profiling Scandal is Just the Start - Virtual Reality is the Next Threat',
        uri: 'https://www.newsweek.com/facebook-virtual-reality-threat-opinion-856102',
      },
    ],
  },
  {
    acceptanceRate: 26,
    conference: 'EC',
    year: 2018,
    authors: '**Redmiles, E.M.**, Mazurek, M.L., and Dickerson, J.P.',
    title: 'Dancing Pigs or Externalities? Measuring the Rationality of ' +
      'Security Decisions',
    uri: 'https://arxiv.org/pdf/1805.06542.pdf',
    bestPresentation: true,
   articles: [
      {
        publisher: 'Schneier on Security',
        title: 'Measuring the Rationality of Security Decisions',
        uri: 'https://www.schneier.com/blog/archives/2018/08/measuring_the_r.html'
      },
    ],
//    presentation: {
  //    source: 'Self',
    //  uri: ec,
    //  config: {
    //    type: 'video/mp4',
    //  },
 //   },
  },
  {
    acceptanceRate: 16,
    conference: 'ICWSM',
    year: 2018,
    authors: '**Redmiles, E.M.**',
    title: 'Net Benefits: Digital Inequities in Social Capital, Privacy ' +
      'Preservation, and Digital Parenting Practices of U.S. Social ' +
      ' Media Users',
    uri: `${process.env.PUBLIC_URL}/research/camera-ready-icwsm2018-redmiles.pdf`,
  },
  {
    acceptanceRate: WORKSHOP,
    conference: 'ConPro',
    year: 2018,
    authors: '**Redmiles, E.M.**, Morales, M., Maszkiewicz, L., ' +
      'Stevens, R., Liu, E., Kuchhal, D., and Mazurek, M.L.',
    title: 'First Steps Toward Measuring the Readability of Security Advice',
    uri: 'https://www.ieee-security.org/TC/SPW2018/ConPro/papers/redmiles-conpro18.pdf',
  },
  {
    acceptanceRate: 15,
    conference: 'WWW',
    year: 2018,
    authors: 'Grgic-Hlaca, N., **Redmiles, E.M.**, Gummadi, K.P., and ' +
      'Weller, A.',
    title: 'Human Perceptions of Fairness in Algorithmic Decision Making:' +
      'A Case Study of Criminal Risk Prediction',
    uri: 'https://arxiv.org/abs/1802.09548',
  },
  {
    acceptanceRate: 26,
    conference: 'CHI',
    year: 2018,
    authors: '**Redmiles, E.M.**, Chachra, N., Waismeyer, B.',
    title: 'Examining the Demand for Spam: Who Clicks?',
    uri: `${process.env.PUBLIC_URL}/research/spam-camera-ready.pdf`,
    articles: [
      {
        publisher: 'Computerworld Australia',
        title: 'Who still falls for social media spam scams?',
        uri: 'https://www.computerworld.com.au/article/632593/who-still-falls-social-media-spam-scams/'
      },
    ],
  },
  {
    acceptanceRate: 14,
    conference: 'IEEE S&P',
    year: 2018,
    authors: 'Votipka, D., Stevens, R., **Redmiles, E.M.**, Hu, J., ' +
      'Mazurek, M.L.',
    title: 'Hackers vs. Testers: A Comparison of Software Vulnerability: ' +
      'Discovery Processes',
    uri: 'https://csdl.computer.org/csdl/proceedings/sp/2018/4353/00/435301a134.pdf',
  },
  {
    acceptanceRate: 30,
    conference: 'iConference',
    year: 2018,
    authors: 'Booth, K. M., Dosono, B., **Redmiles, E.M.**, Morales, M., ' +
      'Depew, M., Farzan, R., Herman, E., Trahan, K., and Tananis, C.',
    title: 'Diversifying the Next Generation of Information Scientists: ' +
      'Six Years of Implementation and Outcomes for a Year-Long REU Program',
    uri: 'https://link.springer.com/chapter/10.1007/978-3-319-78105-1_75',
  },
  {
    acceptanceRate: ARXIV,
    conference: 'UMD Tech Report',
    year: 2017,
    authors: '**Redmiles, E.M.**, Acar, Y., Fahl, S., and Mazurek, M. L.',
    title: 'A Summary of Survey Methodology Best Practices for Security and Privacy Researchers',
    uri: 'https://drum.lib.umd.edu/handle/1903/19227',
  },
  {
    acceptanceRate: 16,
    conference: 'USENIX Security',
    year: 2017,
    authors: 'Plane, A., **Redmiles, E.M.**, Mazurek, M.L., and Tschantz, M.',
    title: 'Exploring User Perceptions of Discrimination in Online ' +
      'Targeted Advertising',
    uri: 'https://www.usenix.org/system/files/conference/usenixsecurity17/sec17-plane.pdf',
    presentation: {
      source: 'Youtube',
      uri: 'https://www.youtube-nocookie.com/embed/9pKF6CTaPg4',
      config: {
        ratio: '16:9',
        title: 'USENIX Presentation: Exploring User Perceptions of '+
         'Discrimination in Online Targeted Advertising',
      },
    },
  },
  {
    acceptanceRate: WORKSHOP,
    conference: 'SOUPS WAY',
    year: 2017,
    authors: '**Redmiles, E.M.**, Liu, E., and Mazurek, M. L.',
    title: 'You Want Me To Do What? A Design Study of Two-Factor ' +
      'Authentication Messages',
    uri: 'https://www.usenix.org/system/files/conference/soups2017/way2017-redmiles.pdf',
  },
  {
    acceptanceRate: WORKSHOP,
    conference: 'CSET',
    year: 2017,
    authors: 'Stransky, C., Acar, Y., Nguyen, D.C., Wermke, D., ' +
      '**Redmiles, E.M.**, Kim, D., Garfinkel, S., Backes, M., Mazurek, ' +
      'M. L., and Fahl, S.',
    title: 'Lessons Learned from Using an Online Platform to Conduct ' +
      'Large-Scale, Online Controlled Security Experiments with ' +
      'Software Developers',
    uri: 'https://www.usenix.org/system/files/conference/cset17/cset17-paper-stransky.pdf',
  },
  {
    acceptanceRate: 25,
    conference: 'CHI',
    year: 2017,
    authors: '**Redmiles, E.M.**, Kross, S., and Mazurek, M.L.',
    title: 'Where is the Digital Divide? A Survey of Security, ' +
      'Privacy, and Socioeconomics',
    uri: `${process.env.PUBLIC_URL}/research/camera-ready-low-ses.pdf`,
    articles: [
      {
        publisher: 'The Conversation',
        title: 'Can better advice keep you safer online?',
        uri: 'https://theconversation.com/can-better-advice-keep-you-safer-online-73419'
      },
      {
        publisher: 'TechRepublic',
        title: '4 critical points to consider when receiving cybersecurity and privacy advice',
        uri: 'https://www.techrepublic.com/article/4-critical-points-to-consider-when-receiving-cybersecurity-and-privacy-advice/'
      },
    ],
  },
  {
    acceptanceRate: 19,
    conference: 'BigData',
    year: 2016,
    authors: '\\*Grace, E., \\*Rai, A., \\***Redmiles, E.M.**, and Ghani, R.',
    title: 'Detecting Fraud, Corruption, and Collusion in International ' +
      'Development Contracts',
    uri: `${process.env.PUBLIC_URL}/research/ieeebigdata2016.pdf`,
    authorsNote: '*This is a co-first authored publication.',
  },
  {
    acceptanceRate: 16,
    conference: 'CCS',
    year: 2016,
    authors: '**Redmiles, E.M.**, Kross, S., and Mazurek, M.L.',
    title: 'How I Learned to be Secure: a Census-Representative Survey of' +
      ' Security Advice Sources and Behavior',
    uri: `${process.env.PUBLIC_URL}/research/camera_ready_ccs2016.pdf`,
    presentation: {
      source: 'Youtube',
      uri: 'https://www.youtube-nocookie.com/embed/FKqsgfbvBeY',
      config: {
        ratio: '16:9',
        title: 'How I Learned to be Secure: a Census-Representative Survey ' +
          'of Security Advice Sources and Behavior',
      },
    },
  },
  {
    acceptanceRate: 13,
    conference: 'IEEE S&P',
    year: 2016,
    authors: '**Redmiles, E.M.**, Malone, A.R., and Mazurek, M. L.',
    title: 'I Think They\'re Trying to Tell Me Something: Advice Sources ' +
      'and Selection for Digital Security',
    uri: 'http://www.ieee-security.org/TC/SP2016/papers/0824a272.pdf',
    presentation: {
      source: 'Youtube',
      uri: 'https://www.youtube-nocookie.com/embed/ylLcK4BpIGo',
      config: {
        ratio: '16:9',
        title: 'I Think They\'re Trying to Tell Me Something: Advice ' +
          'Sources and Selection for Digital Security',
      },
    },
  },
  {
    acceptanceRate: 31,
    conference: 'SIGCSE',
    year: 2015,
    authors: '**Redmiles, E.M.**, Abad, M., Coronado, I., Kross, S., and ' +
      'Malone, A.',
    title: 'A Classroom Tested Accessible Multimedia Resource for ' +
      'Engaging Underrepresented Students in Computing: The University of ' +
      'Maryland Curriculum In A Box',
    uri: 'https://dl.acm.org/citation.cfm?id=2691870',
  },
];

export {
  TBD,
  WORKSHOP,
  PUBLICATIONS,
  JOURNAL,
  CHAPTER,
  ARXIV,
  DISSERTATION
}

export default PUBLICATIONS
